<template>
	<div class="wrapper" v-loading="loading">
		<div v-if="!userId" class="top-btn" ref="topBtn">
			<div>
				<el-select class="w110" v-model="search.search_gist" filterable placeholder="请选择" size="small">
					<el-option v-for="it in searchGist" :key="it.value" :label="it.label" :value="it.value"></el-option>
				</el-select>
				<el-input class="w140" size="small" placeholder="请输入" v-model="search.desp" clearable> </el-input>
				<el-button size="small" type="primary" @click="searchList()">搜索</el-button>
			</div>
			<div>
				<el-select class="w110" size="small" v-model="search.order_status" clearable @change="searchList()">
					<template v-for="(value,key) in $constant.orderStatus">
						<el-option v-if="key<=5" :key="key" :label="value" :value="key"></el-option>
					</template>
				</el-select>
				<el-select class="w110" size="small" v-model="search.timeType">
					<el-option :key="1" label="下单时间" :value="1"></el-option>
					<el-option :key="2" label="付款时间" :value="2"></el-option>
				</el-select>
				<el-date-picker size="small" style="width: 230px" @change="searchList()"
					v-model="search.time"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
			</div>
		</div>
		<el-table 
			:data="list" 
			:max-height="tableHeight"
			:header-cell-style="{background:'#f5f7fa'}"
			style="width: 100%">
			<el-table-column prop="order_time" label="下单时间">
				<template slot-scope="scope">{{ scope.row.order_time*1000 | formatDate }}</template>
			</el-table-column>
			<el-table-column prop="order_no" label="订单号"></el-table-column>
			<el-table-column prop="coupon_name" label="优惠券名称"></el-table-column>
			<el-table-column prop="face_value" label="优惠券面额"></el-table-column>
			<el-table-column prop="course_price" label="优惠券兑换价格"></el-table-column>
			<el-table-column prop="org_name" label="机构"></el-table-column>
			<el-table-column prop="ShortName" label="机构城市"></el-table-column>
			<el-table-column prop="member_name" label="购买用户"></el-table-column>
			<el-table-column prop="contacts_phone" label="用户手机号"></el-table-column>
			<el-table-column prop="contacts_name" label="联系人称呼"></el-table-column>
			<el-table-column prop="pay_price" label="实付金额"></el-table-column>
			<el-table-column prop="pay_time" label="付款时间">
				<template slot-scope="scope">{{ scope.row.pay_time*1000 | formatDate }}</template>
			</el-table-column>
			<el-table-column prop="verify_time" label="核销时间">
				<template slot-scope="scope">{{ scope.row.verify_time*1000 | formatDate }}</template>
			</el-table-column>
			<el-table-column prop="status" label="状态">
				<template slot-scope="scope">
					{{ $constant.orderStatus[scope.row.status] }}
				</template>
			</el-table-column>
			<el-table-column label="操作" min-width="80">
				<template slot-scope="scope">
					<el-link type="primary" @click="detail(scope.row.id)">详情</el-link>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination v-if="total > 0" style="padding-top: 15px; text-align: center;" @current-change="handleCurrentChange"
		:current-page.sync="currentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="total">
		</el-pagination>
		<orderDetail ref="detail" :dialogVisible='dialogVisible' @close="close"></orderDetail>
	</div>
</template>
<script>
	import { orderList } from '@/api/courseOrder'
	import orderDetail from './component/orderDetail.vue'
	export default {
		name: "OrderList",
		props: {
			userId: { // 用户列表详情里
				type: String,
				default: ''
			},
		},
		data() {
			return {
				loading: false,
				tableHeight: this.$constant.maxHeight,
				//搜索依据
				searchGist: [{
					value: '微信昵称',
					label: '微信昵称'
				}, {
					value: '手机号',
					label: '手机号'
				}, {
					value: '订单号',
					label: '订单号'
				}, {
					value: '机构名称',
					label: '机构名称'
				}, {
					value: '优惠券名称',
					label: '优惠券名称'
				}],
				// 搜索条件
				search: {
					search_gist: '微信昵称',
					desp: '',
					order_status: '',
					timeType: 1,
					time: '',
				},
				total: 0,
				limit: 15,
				currentPage: 1,
				list: [],
				// 弹框
				dialogVisible: false,
				id: 0,
			}
		},
		components: {
			orderDetail
		},
		mounted() {
			this.getList()
			this.tableHeight = this.userId ? this.$common.setHeight([], 250) : this.$common.setHeight([this.$refs.topBtn], 130)
			window.onresize = this.$common.debounce(()=>{
				this.tableHeight = this.userId ? this.$common.setHeight([], 250) : this.$common.setHeight([this.$refs.topBtn], 130)
			}, 1000)
		},
		methods: {
			// 获取列表
			getList() {
				let data = {}
				if(this.userId) {
					data = {
						limit: this.limit,
						page: this.currentPage,
						order_type: [2],
						member_id: this.userId,
					}
				}else { 
					data = {
						limit: this.limit,
						page: this.currentPage,
						order_type: [2],
						order_status: this.search.order_status,
						wx_nickname: this.search.search_gist == '微信昵称'?this.search.desp:null, //昵称搜索
						contacts_phone:  this.search.search_gist == '手机号'?this.search.desp:null, //电话搜索
						order_no:  this.search.search_gist == '订单号'?this.search.desp:null, //订单号搜索
						org_name: this.search.search_gist == '机构名称'?this.search.desp:null,//机构名搜索
						course_name: this.search.search_gist == '优惠券名称'?this.search.desp:null,//优惠券名称
						order_start_time: this.search.timeType == 2 ? this.$common.setSearchTime(this.search.time)[0] : null,
						order_end_time: this.search.timeType == 2 ? this.$common.setSearchTime(this.search.time)[1] : null,
						pay_start_time: this.search.timeType == 1 ? this.$common.setSearchTime(this.search.time)[0] : null,
						pay_end_time: this.search.timeType == 1 ? this.$common.setSearchTime(this.search.time)[1] : null,
					}
				}
				orderList(data).then((res) => {
					this.total = res.data.total;
					this.list = res.data.data;
					this.loading = false;
				}).catch((err) => {
					this.loading = false;
					console.log(err)
				})
			},
			// 搜索
			searchList() {
				this.loading = true
				this.currentPage = 1;
				this.getList();
			},
			// 页码修改
			handleCurrentChange(val) {
				this.loading = true
				this.currentPage = val;
				this.getList();
			},
			// 订单详情
			detail(id) {
				this.$refs.detail.getOrderDetail(id)
				this.dialogVisible = true
			},
			// 关闭弹框
			close() {
				this.dialogVisible = false
			},
		}
	};
</script>
