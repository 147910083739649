<template>
	<el-dialog title="详情" :visible.sync="dialogVisible" width="900px" :before-close="handleClose">
		<div class="flex">
			<el-form :inline="true" :model="formInline" class="demo-form-inline" style="width: 45%;display: flex;flex-direction: column;" label-width="100px">
				<el-form-item label="订单号:">
					<div>{{formInline.order_no}}</div>
				</el-form-item>
				<el-form-item label="优惠券名称:">
					<div>{{formInline.coupon_name}}</div>
				</el-form-item>
				<el-form-item label="优惠券价格:">
					<div>{{formInline.course_price}}</div>
				</el-form-item>
				<el-form-item label="状态:">
					<div>{{ $constant.orderStatus[formInline.status] }}</div>
				</el-form-item>
				<el-form-item label="购买时间:">
					<div>{{formInline.order_time}}</div>
				</el-form-item>
				<el-form-item label="需付金额:">
					<div>{{formInline.course_price}}</div>
				</el-form-item>
				<el-form-item label="红包抵用金:">
					<div>{{formInline.discount_price}}</div>
				</el-form-item>
				<el-form-item label="实付金额:">
					<div>{{formInline.pay_price}}</div>
				</el-form-item>
				<el-form-item label="支付时间:">
					<div>{{formInline.pay_time}}</div>
				</el-form-item>
				<el-form-item label="过期时间:">
					<div>{{formInline.expiration_time}}</div>
				</el-form-item>
				<el-form-item label="核销时间:">
					<div>{{formInline.verify_time}}</div>
				</el-form-item>
			</el-form>
			<!-- 右边 -->
			<el-form :inline="true" :model="formInline" class="demo-form-inline" style="width: 45%;display: flex;flex-direction: column;" label-width="100px">
				<el-form-item label="购买用户:">
					<div>{{formInline.member_name || '--'}}</div>
				</el-form-item>
				<el-form-item label="用户手机号:">
					<div>{{formInline.contacts_phone || '--'}}</div>
				</el-form-item>
				<el-form-item label="联系人称呼:">
					<div>{{formInline.contacts_name || '--'}}</div>
				</el-form-item>
				<el-form-item label="机构:">
					<div>{{formInline.org_name}}</div>
				</el-form-item>
				<el-form-item label="机构城市:">
					<div>{{formInline.ShortName}}</div>
				</el-form-item>
				<el-form-item label="关联视频id:">
					<div>{{formInline.video_id || '--'}}</div>
				</el-form-item>
				<el-form-item label="分享人id:">
					<div>{{formInline.share_member_id || '--'}}</div>
				</el-form-item>
			</el-form>
		</div>
	</el-dialog>
</template>

<script>
	import { orderDetail } from '@/api/courseOrder.js';
	import filter from '@/util/filters.js'
	export default {
		name: 'OrderDetail',
		props: {
			dialogVisible: {
				type: Boolean,
				default: false
			},
		},
		data() {
			return {
				formInline: {}
			};
		},
		methods: {
			handleClose() {
				this.$emit('close')
			},
			// 获取订单信息
			getOrderDetail(id) {
				orderDetail({
					order_id: id,
					order_type: 2
				}).then(res => {
					this.formInline = res.data
					this.formInline.order_time = filter.formatDate(this.formInline.order_time * 1000)
					this.formInline.expiration_time = filter.formatDate(this.formInline.expiration_time * 1000)
					this.formInline.pay_time = filter.formatDate(this.formInline.pay_time * 1000)
					this.formInline.verify_time = filter.formatDate(this.formInline.verify_time * 1000)
				}).catch(rej => {
					console.log(rej)
				})
			}
		}
	}
</script>

<style scoped>
	.flex {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
		border-bottom: 1px solid #dedede;
	}
</style>
